import { WEBSITE_URL } from "./website";


//CUSTOMER API ROUTES

export const LOGIN_OTP_USER_URL = WEBSITE_URL + "/public/api/login-otp-user";
export const SEND_CUSTOM_OTP_URL = WEBSITE_URL + "/public/api/custom-otp-user";
export const USER_UPDATE_USER_INFO_URL = WEBSITE_URL + "/public/api/user-update-details";
export const UPDATE_USER_INFO_URL = WEBSITE_URL + "/public/api/user-update-info";
export const REGISTER_USER_OTP_URL = WEBSITE_URL + "/public/api/register-otp-user";
export const VIEW_ITEM_GROUP_ITEMS_URL = WEBSITE_URL + "/public/api/item-group-items";
export const VIEW_SLIDER_STORES_URL = WEBSITE_URL + "/public/api/get-slider-stores";
export const VIEW_BANNER_ITEMS_URL = WEBSITE_URL + "/public/api/banner-items";
export const VIEW_SINGLE_STORE_URL = WEBSITE_URL + "/public/api/single-store";
export const VIEW_LOYALTY_URL = WEBSITE_URL + "/public/api/view-loyalty";
export const USER_MILESTONE_URL = WEBSITE_URL + "/public/api/user-milestone";
export const VIEW_MEMBERSHIP_URL = WEBSITE_URL + "/public/api/view-membership";
export const GET_MEMBERSHIP_USER_URL = WEBSITE_URL + "/public/api/unlock-membership-user";
export const JOIN_MEMBERSHIP_URL = WEBSITE_URL + "/public/api/join-membership";
export const MEMBERSHIP_SUCCESS_URL = WEBSITE_URL + "/public/api/payment/member-status-paytm";
// export const MEMBERSHIP_SUCCESS_URL = WEBSITE_URL + "/public/api/membership-success";
export const SCRATCH_MILESTONE_URL = WEBSITE_URL + "/public/api/scratch-loyalty";


export const VIEW_SINGLE_ITEM_URL = WEBSITE_URL + "/public/api/single-item";
export const SEARCH_ITEMS_URL = WEBSITE_URL + "/public/api/search-items";
export const GET_ADDRESS_FROM_COORDINATES = WEBSITE_URL + "/public/api/coordinate-to-address";
export const GET_USER_ADDRESSES = WEBSITE_URL + "/public/api/get-addresses-user";
export const SAVE_ADDRESS_URL = WEBSITE_URL + "/public/api/save-address";
export const SET_DEFAULT_ADDRESS_URL = WEBSITE_URL + "/public/api/set-default-address";
export const DELETE_ADDRESS_URL = WEBSITE_URL + "/public/api/delete-address";
export const GET_USER_ORDERS = WEBSITE_URL + "/public/api/user-orders";
export const GET_ORDER_VIEW = WEBSITE_URL + "/public/api/order/order-view";
export const GET_USER_OFFERS_URL = WEBSITE_URL + "/public/api/view-user-coupons";
export const GET_USER_WALLET_URL = WEBSITE_URL + "/public/api/view-user-wallet";
export const GET_NEAR_BRAND_STORE_URL = WEBSITE_URL + "/public/api/get-near-brand-store";

export const DELETE_USER_URL = WEBSITE_URL + "/public/api/delete-user";

//cart
export const APPLY_COUPON_URL = WEBSITE_URL + "/public/api/apply-coupon";
export const AUTO_APPLY_COUPON_URL = WEBSITE_URL + "/public/api/auto-apply-coupon";
export const CHECK_CART_ITEMS_AVAILABILITY_URL = WEBSITE_URL + "/public/api/check-cart-items-availability";
export const GET_STORE_INFO_AND_OPERATIONAL_STATUS_URL = WEBSITE_URL + "/public/api/get-store-operational";
export const PLACE_ORDER_URL = WEBSITE_URL + "/public/api/place-order";

// home page
export const GET_ITEM_GROUPS_URL = WEBSITE_URL + "/public/api/get-item-groups";
export const GET_ITEM_BANNERS_URL = WEBSITE_URL + "/public/api/get-home-banners";
export const GET_TRENDING_BRANDS_URL = WEBSITE_URL + "/public/api/get-trending-brands";
export const GET_BRAND_STORES = WEBSITE_URL + "/public/api/get-brand-stores";
export const GET_STORE_SLIDERS_URL = WEBSITE_URL + "/public/api/get-home-sliders";
export const GET_POPULAR_ITEMS_URL = WEBSITE_URL + "/public/api/get-popular-items";
export const GET_STORES_URL = WEBSITE_URL + "/public/api/get-stores";

//CUSTOMER API ROUTES ENDS


export const GET_SETTINGS_URL = WEBSITE_URL + "/public/api/get-settings";

export const GET_ALL_LANGUAGES_URL = WEBSITE_URL + "/public/api/get-all-languages";
export const GET_SINGLE_LANGUAGE_DATA_URL = WEBSITE_URL + "/public/api/get-single-language";

export const LOGIN_STORE_USER_URL = WEBSITE_URL + "/public/api/store/login";
export const UPDATE_STORE_USER_INFO_URL = WEBSITE_URL + "/public/api/store/dashboard";
export const CANCEL_ORDER_URL = WEBSITE_URL + "/public/api/cancel-order";
export const GET_CALCULATE_STORE_MAX_DISTANCE = WEBSITE_URL + "/public/api/store-distance";
export const GET_CALCULATE_STORE_STORE_CHARGE_TAX = WEBSITE_URL + "/public/api/calculate-store-charge-tax";
export const GET_DELIVERY_SLAB_URL = WEBSITE_URL + "/public/api/get-distance-delivery-slab";
export const ADD_RATING_URL = WEBSITE_URL + "/public/api/rate-order";
export const GET_RATABLE_ORDER_DETAILS_URL = WEBSITE_URL + "/public/api/get-ratable-order";
export const GET_REVIEWS_OF_STORE_URL = WEBSITE_URL + "/public/api/get-store-reviews";
export const VIEW_ALL_CITIES_URL = WEBSITE_URL + "/public/api/view/cities";

//PAYTM
export const PAYTM_PAYMENT_URL = WEBSITE_URL + "/public/api/payment/paytmtwo";
export const PAYTM_MEMBER_PAYMENT_URL = WEBSITE_URL + "/public/api/payment/member-paytm";
export const PAYTM_STATUS_CHECK_URL = WEBSITE_URL + "/public/api/payment/status-check";

export const    REMOVE_DEVICE_TOKEN_URL = WEBSITE_URL + "/public/api/update-device-token-2";
